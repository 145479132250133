// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import shallow from "zustand/shallow";

import dataLayerPush from "@assets/js/gtm";
import globalJs, { initBasket } from "@assets/js/global";

import useGlobalStore from "@store/global";
import { usePLPPrices } from "@store/price";
import Cookies from "universal-cookie";
import { COOKIES, DATALAYER_EVENTS } from "@constants";

const gqlQuery = loader("./assets/js/gql/country-selector.graphql");

/**
 * This is the first functional component in the solution and also where initial API calls
 * and non React related JS are initiated.
 *
 */

const AppEssentials = ({ children }) => {
  const [
    siteInitComplete,
    setSiteInitComplete,
    setMarketInfo,
    commerceMarket,
    commerceMarketSupportsEcom,
  ] = useGlobalStore(
    (state) => [
      state.siteInitComplete,
      state.setSiteInitComplete,
      state.setMarketInfo,
      state.commerceMarket,
      state.commerceMarketSupportsEcom,
    ],
    shallow
  );

  const [, fetchPLPPrices] = usePLPPrices();
  const cookies = new Cookies();
  
  //load markets
  useQuery(gqlQuery, {
    variables: {
      siteRoot: process.env.REACT_APP_QUERY_VARIABLES_SITE_ROOT,
    },
    errorPolicy: "all",
    onCompleted: (response) => {
      // log("AppEssentials | market query loaded");
      //when loaded, set in global store
      setMarketInfo(response.item || {});
    },
  });

  useEffect(() => {
    if (commerceMarket?.length) {
      // log("AppEssentials | commerceMarket=" + commerceMarket);
      // once commerce market populates, load plp prices
      fetchPLPPrices(commerceMarket);


      // get or create basket

      //JPL this line results in a skipped initbasket for non-commercemarkets, 
      // which leads to no basket id, 
      // which leads to not being able to call changeBasketMarketCountry (in basket store)
      
      if (commerceMarketSupportsEcom && !siteInitComplete) initBasket(commerceMarket);      
      
      if ( !siteInitComplete) setSiteInitComplete(true);
    }
  }, [siteInitComplete, setSiteInitComplete, commerceMarket, commerceMarketSupportsEcom, fetchPLPPrices]);

  useEffect(() => {
    if (commerceMarket?.length) {
      // GTM tracking
      dataLayerPush({
        event: DATALAYER_EVENTS.VIRTUALPAGEVIEW,
        userCountry: useGlobalStore.getState().commerceMarket || 'INT',
        businessType: useGlobalStore.getState().currentSiteMode,
        userId: cookies.get(COOKIES.trackingUserId.name),
        loginStatus: useGlobalStore.getState().userAuthenticated ? "logged in" : ""
      });
    }
  }, [commerceMarket])

  useEffect(() => {
    // initiate global non React related JS
    globalJs();
  }, []);

  return children;
};

export default AppEssentials;
