import { QUERY_PARAMETER } from "@constants";

export const setURLParams = (items) => {
  // if items is empty the queryParams will re reset
  let params = items
    ? new URLSearchParams(window.location.search)
    : new URLSearchParams();

  if (items) {
    for (const [key, value] of Object.entries(items)) {
      if (value && value.length) {
        params.set(key, value);
      } else if (params.has(key)) {
        params.delete(key);
      }
    }
  }
  if (window.history && window.history.pushState) {
    let newUrl = `${window.location.protocol}//${
      window.location.host + window.location.pathname
    }`;
    if (items) {
      newUrl = `${newUrl}?${params.toString()}`;
    }

    window.history.pushState({ newUrl: newUrl }, "User configuration", newUrl);
  } else {
    window.location.search = params.toString();
  }
};
export const getURLParams = (location = null) => {
  // take url params from the browser window and remove escaped ampersand
  if(location){
    return new URLSearchParams(location.search.replace(/&amp;/g, "&"));
  }
  return new URLSearchParams(window.location.search.replace(/&amp;/g, "&"));
};

export const getModelTypeFromUrl = (location) =>{
    return location.pathname.split('/').filter(x => !!x)[location.pathname.split('/').filter(x => !!x).length-1]
}

export const getUniqueMaterialIndex = () => {
  let uniqueMaterialString = null;
  let uniqueMaterialIndex = null;
  var qsKeys = new URLSearchParams(window.location.search);
  qsKeys.forEach(function(value, key) {
    if(key.includes(QUERY_PARAMETER.UNIQUE_MATERIAL)){
      uniqueMaterialIndex = key.split(QUERY_PARAMETER.UNIQUE_MATERIAL)[1];
      uniqueMaterialString = value;
    };
  });

  if(uniqueMaterialIndex && uniqueMaterialString){
    return {
      index: uniqueMaterialIndex,
      uniqueMaterialString: uniqueMaterialString
    }
  }
  return null;
}

export const getSKUParams = (str) => {
  const sku = str
    ? str
    : new URLSearchParams(window.location.search).get(QUERY_PARAMETER.SKU);
  if (!sku) return null;

  const idParts = sku.split("_");
  const skuParts = idParts[0].split("-"); 
  let optionParts = null;
  if(idParts.length > 1){
    optionParts = idParts[1].split("-");
  }

  let skuParams = {};
  const skuParamCount = skuParts.length;
  if (skuParamCount > 0) skuParams.modelTypeId = skuParts[0];
  if (skuParamCount > 1) {
    for (let i = 1; i < skuParamCount; i++) {
      skuParams[`material${i}Id`] = skuParts[i];
    }
  }
  if(optionParts){
    let options = [];
    const optionParamCount = optionParts.length;
    for(let i = 0; i < optionParamCount; i++){
      options.push(optionParts[i]);
    }
    skuParams[`options`] = options;
  }
  // console.log("getSKUParams", skuParams);
  return skuParams;
};
export const getSKUString = () => {
  return new URLSearchParams(window.location.search).get(QUERY_PARAMETER.SKU);
};
export const getUniqueMaterialString = (materialIndex) => {
  return new URLSearchParams(window.location.search).get(QUERY_PARAMETER.UNIQUE_MATERIAL + materialIndex);
};
export const getPageString = (location = null) => {
  if (location) {
    return new URLSearchParams(location.search).get(QUERY_PARAMETER.PAGE);
  }
  return new URLSearchParams(window.location.search).get(QUERY_PARAMETER.PAGE);
}
export const setSKUParams = (params) => {
  let skuStr = params.model;
  if (params.materials && params.materials.length > 0) skuStr += "-";
  skuStr += params.materials.join("-");
  // console.log("setSKUParams", skuStr);
  setURLParams({ sku: skuStr });
};

const index = {
  set: setURLParams,
  get: getURLParams,
  getSKU: getSKUParams,
  setSKU: setSKUParams,
  getSKUStr: getSKUString,
  getUniqueMaterialString: getUniqueMaterialString,
  getUniqueMaterialIndex: getUniqueMaterialIndex,
  getPageString: getPageString,
  getModelTypeFromUrl : getModelTypeFromUrl
};
export default index;
