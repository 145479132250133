import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";

import MainHeader from "./MainHeader";
import MenuOverlay from "../MenuOverlay";
import MegaMenu from "./MegaMenu";
import MyFritzMenu from "./MyFritzMenu";
import ProductFiltersList from "../ProductFiltersList";
import PortfolioSubmissionController from "../portfolio/PortfolioSubmissionController";
import PortfolioAdminController from "../portfolio/PortfolioAdminController";
import MiniBasketController from "../checkout/MiniBasketController";
import MediaInfoOverlay from "../MediaInfoOverlay";
import MaterialInfoOverlay from "../MaterialInfoOverlay";
import SiteSearchController from "../search/SiteSearchController";
import CheckoutHeader from "../CheckoutHeader";

import { SITE_MODE, OVERLAY, QUERY_PARAMETER } from "@constants";
import isCheckoutLayout from "@assets/js/is-checkout-layout";
import urlParams from "@assets/js/url-params";
import NotifyMeOverlay from "../NotifyMeOverlay";

import { useCommerceMarketSupportsEcom, useCommerceMarket } from "@store/global";
import { useMenuOverlayActive } from "@store/header";

const { RESIDENTIAL } = SITE_MODE;
const ConnectedHeaderMenuQuery = loader("./headerMenuQuery.graphql");

const SiteHeader = ({ mode, layoutId, fields }) => {
  // Async loading and error handling
  // Remember to never return null from a JSS component when loading,
  // this will break Experience Editor.
  // const { error, loading } = headerMenuQuery;
  let dqId;
  let mqId;
  if (mode === RESIDENTIAL) {
    dqId = process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID;
    mqId = process.env.REACT_APP_RESIDENTIAL_MOBILE_ID;
  } else {
    dqId = process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;
    mqId = process.env.REACT_APP_PROFESSIONAL_MOBILE_ID;
  }
  const desktop = useQuery(ConnectedHeaderMenuQuery, {
    variables: { menuId: dqId },
    errorPolicy: "all",
  });
  const mobile = useQuery(ConnectedHeaderMenuQuery, {
    variables: { menuId: mqId },
    errorPolicy: "all",
  });

  // useEffect(() => {
  //   const {
  //     loading: desktopLoading,
  //     data: desktopData,
  //     error: desktopError,
  //   } = desktop;
  //   const {
  //     loading: mobileLoading,
  //     data: mobileData,
  //     error: mobileError,
  //   } = mobile;

  // }, [desktop, mobile]);
  const {
    loading: desktopLoading,
    data: desktopData,
    error: desktopError,
  } = desktop;
  const {
    loading: mobileLoading,
    data: mobileData,
    error: mobileError,
  } = mobile;

  // Query results load in using the name of their root field (see query.graphql)
  const mainMenu = {
    loading: desktopLoading || mobileLoading,
    error: desktopError || mobileError,
    desktop: !desktopLoading && desktopData ? desktopData.menu : [],
    mobile: !mobileLoading && mobileData ? mobileData.menu : [],
  };

  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();
  const [commerceMarket] = useCommerceMarket();
  const [, setMenuOverlayActive] = useMenuOverlayActive();

  // useEffect(() => {
  //   var queryParam = urlParams.get().get(QUERY_PARAMETER.QUERY);
  //   if (commerceMarket && queryParam) {
  //     setMenuOverlayActive(OVERLAY.SEARCH);
  //   }
  // }, [commerceMarket]);

  return (
    <header className="site-header">
      {isCheckoutLayout(layoutId) ? (
        <CheckoutHeader
          continueShoppingLink={fields?.continueShoppingLink?.value?.href}
        />
      ) : (
        <MainHeader menu={mainMenu} fields={fields} />
      )}
      <MenuOverlay id={OVERLAY.BASKET}>
        <MiniBasketController />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.MENU}>
        <MegaMenu menu={mainMenu} />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.MYFRITZ}>
        <MyFritzMenu supportsEcom={commerceMarketSupportsEcom} />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.FILTERS} className={'filterOverlay'}>
        <ProductFiltersList menu={mainMenu} />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.PORTFOLIO_SUBMISSION}>
        <PortfolioSubmissionController />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.PORTFOLIO_ADMIN}>
        <PortfolioAdminController />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.MEDIA}>
        <MediaInfoOverlay />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.MATERIAL}>
        <MaterialInfoOverlay />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.SEARCH}>
        <SiteSearchController />
      </MenuOverlay>
      <MenuOverlay id={OVERLAY.NOTIFY_ME}>
        <NotifyMeOverlay />
      </MenuOverlay>
      {/* { process.browser && <HeaderMenuGQL menuType={"CONSUMERS"} />} */}
      {/*process.browser && <HeaderMenuGQL menuType={"PROFESSIONALS"} />*/}
    </header>
  );
};

// export default ({ mode = RESIDENTIAL }) => {
//   if (!process.browser) return <></>;
//   if (mode === RESIDENTIAL) {
//     SiteHeader = compose(
//       GraphQLData(ConnectedHeaderMenuQuery, {
//         name: "consDesktopMenuQuery",
//         options: {
//           variables: { menuId: process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID },
//         },
//       }),
//       GraphQLData(ConnectedHeaderMenuQuery, {
//         name: "consMobileMenuQuery",
//         options: {
//           variables: { menuId: process.env.REACT_APP_RESIDENTIAL_MOBILE_ID },
//         },
//       })
//     )(SiteHeader);
//   } else if (mode === PROFESSIONAL) {
//     SiteHeader = compose(
//       GraphQLData(ConnectedHeaderMenuQuery, {
//         name: "profDesktopMenuQuery",
//         options: {
//           variables: { menuId: process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID },
//         },
//       }),
//       GraphQLData(ConnectedHeaderMenuQuery, {
//         name: "profMobileMenuQuery",
//         options: {
//           variables: { menuId: process.env.REACT_APP_PROFESSIONAL_MOBILE_ID },
//         },
//       })
//     )(SiteHeader);
//   } else {
//     console.log("no header currently");
//   }
//   return <SiteHeader mode={mode} />;
// };

export default SiteHeader;
